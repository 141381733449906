import logo from './logo.svg';
import './App.css';
import MainComponent from './components'
function App() {
   return (
      <MainComponent />
   );
}

export default App;
